<template>
  <div :class="['cat-heading']">
    <atomic-image v-if="icon" :src="`${gamehubCdn}/icons/categories/${icon}.png`" :width="32" :height="32" />
    <slot />
  </div>
</template>

<script setup lang="ts">
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  defineProps<{
    icon?: string;
  }>();
</script>

<style src="~/assets/styles/components/atomic/cat-heading.scss" lang="scss" />
